import { call, put, takeEvery } from 'redux-saga/effects';
import { ActionTypes, Actions } from './reducers';
import apiService, { ApiCallPayload } from '../../services/api/api-service';
import CommandBus from '../../infrastructure/command-bus/command-bus';

// Action tipleri
interface Action<T> {
    type: string;
    payload: T;
}

function* BaseApiCall({ payload }: Action<ApiCallPayload>): any {
    try {
        const token = localStorage.getItem('token');
        const apiCall = yield call(apiService.makeApiCall, { // make Request
            controller: payload.controller,
            action: payload.action,
            query: payload.query,
            data: payload.data,
            method: payload.method,
            itemId: payload.itemId,
            token,
            headers: payload.headers,
        } as ApiCallPayload);

        if (apiCall.success && payload.onSuccess instanceof Function)
            yield call(payload.onSuccess, { data: apiCall.data, pagination: apiCall.pagination });

        if (apiCall.error && payload.onError instanceof Function)
            yield call(payload.onError, { data: apiCall.data, errorMessage: apiCall.errorMessage });

        if (payload.callback instanceof Function)
            yield call(payload.callback, apiCall)
    } catch (error: any) {
        CommandBus.sc.alertError(error?.message);
    }
}

function* NativeGetCall({ payload }: Action<ApiCallPayload>) {
    yield put(Actions.BaseApiCall({ ...payload, method: "get" }));
}

function* NativePostCall({ payload }: Action<ApiCallPayload>) {
    yield put(Actions.BaseApiCall({ ...payload, method: "post" }));
}

function* Detail({ payload }: Action<ApiCallPayload>) {
    yield put(Actions.BaseApiCall({
        ...payload,
        action: 'detail',
        itemId: payload.itemId,
        query: payload.query,
        method: "get",
    }));
}

function* Update({ payload }: Action<ApiCallPayload>) {
    yield put(Actions.BaseApiCall({
        ...payload,
        itemId: payload.itemId,
        method: "put",
    }));
}

function* Save({ payload }: Action<ApiCallPayload>) {
    yield put(Actions.BaseApiCall({
        ...payload,
        action: null,
        method: "post",
    }));
}

function* Delete({ payload }: Action<ApiCallPayload>) {
    yield put(Actions.BaseApiCall({
        ...payload,
        action: null,
        itemId: payload.itemId,
        method: "delete",
    }));
}

function* List({ payload }: Action<ApiCallPayload>) {
    yield put(Actions.BaseApiCall({
        ...payload,
        method: "post",
        action: "list",
    }));
}

function* Dropdown({ payload }: Action<ApiCallPayload>) {
    yield put(Actions.BaseApiCall({
        action: "dropdown",
        ...payload,
        method: "get",
    }));
}

function* UpdateStatus({ payload }: Action<ApiCallPayload>) {
    yield put(Actions.BaseApiCall({
        ...payload,
        action: "UpdateStatus",
        method: "put",
        itemId: payload.itemId,
        status: payload.status
    }));
}

export default [
    takeEvery(ActionTypes.NATIVE_GET, NativeGetCall),
    takeEvery(ActionTypes.NATIVE_POST, NativePostCall),
    takeEvery(ActionTypes.DELETE, Delete),
    takeEvery(ActionTypes.DETAIL, Detail),
    takeEvery(ActionTypes.SAVE, Save),
    takeEvery(ActionTypes.UPDATE, Update),
    takeEvery(ActionTypes.LIST, List),
    takeEvery(ActionTypes.UPDATE_STATUS, UpdateStatus),
    takeEvery(ActionTypes.BASE_API_CALL, BaseApiCall),
    takeEvery(ActionTypes.DROPDOWN, Dropdown),
];

import { DeleteOutlined, DownloadOutlined, EyeOutlined, FileDoneOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Modal } from "antd";
import i18next from "i18next";
import React, { useCallback, useEffect, useRef, useState } from "react";
import general from "../../../utils/general";
import useRefState from "../../../utils/use-ref-state";
import GridPopconfirmAction from "../virtualized-grid/grid-popconfirm-action";
import { FormAction } from "./";
import ImageSlider from "./image-slider";
import { Actions as ModalActions } from "../../../redux/modal/reducers";
import ModalTypes from "../../../utils/modal-types";
import { useDispatch } from "react-redux";
import apiService from "../../../services/api/api-service";

const FormMultiFileUpload = ({
  maxFileCount = 5,
  value = "[]",
  bucket,
  readOnly,
  errorMessage,
  antdFormItemProps,
  onChange,
  label,
  onChangeLoading,
  accept,
  hideWhenNotFileUploadedOnReadOnlyMode,
  multiValue,
  onDeleteChange,
  cropFile,
}) => {
  const dispatch = useDispatch();
  const [files, filesRef, setFiles] = useRefState([]);
  const [keyForInput, setKeyForInput] = useState(general.generateRandomString(10));
  const [imageViewerState, setImageViewerState] = useState({ modalOpen: false, url: "", urlList: [] });

  useEffect(() => {
    let _files = [];
    if (value?.includes("[") && value?.includes("]")) _files = JSON.parse(value);
    else _files = [value]
    setFiles(_files);
  }, [value]);

  const [loading, setLoading] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);

  // const deleteFile = useCallback((index) => {
  //   const deletedItem = filesRef.current[index];
  //   if (onDeleteChange instanceof Function) onDeleteChange(deletedItem);
  //   else onChange(JSON.stringify([...filesRef.current.filter((x) => x[fileName] !== deletedItem[fileName])]));
  // }, []);

  const deleteFile = (file) => async () => {
    const newFiles = filesRef.current.filter((x) => x !== file);
    setFiles(newFiles);
    if (onDeleteChange instanceof Function) onDeleteChange(file);
    else onChange(JSON.stringify(newFiles));
  };

  // const showImage = useCallback((index) => {
  //   const file = filesRef.current[index];
  //   const urlList = filesRef.current.map((file) => file[fileName]).filter((url) => url); // Tüm[fileName]'leri al ve boş olmayanları filtrele
  //   let urlListt = [];
  //   if (multiValue) {
  //     const index = multiValue.findIndex((item) => item === urlList[0]);
  //     if (index !== -1) {
  //       multiValue = [...urlList, ...multiValue.slice(index + 1), ...multiValue.slice(0, index)];
  //     }
  //   } else {
  //     const index = urlList.findIndex((item) => item === file[fileName]);
  //     if (index !== -1) {
  //       urlListt = [file[fileName], ...urlList.slice(index + 1), ...urlList.slice(0, index)];
  //     }
  //   }
  //   setImageViewerState({ modalOpen: true, url: file?.[fileName], urlList: multiValue ? multiValue : urlListt });
  // }, []);

  const showImage = (file) => () => {
    const urlList = filesRef.current.filter((url) => url); // Tüm[fileName]'leri al ve boş olmayanları filtrele
    let urlListt = [];
    if (multiValue) {
      const index = multiValue.findIndex((item) => item === urlList[0]);
      if (index !== -1) {
        multiValue = [...urlList, ...multiValue.slice(index + 1), ...multiValue.slice(0, index)];
      }
    } else {
      const index = urlList.findIndex((item) => item === file);
      if (index !== -1) {
        urlListt = [file, ...urlList.slice(index + 1), ...urlList.slice(0, index)];
      }
    }
    setImageViewerState({ modalOpen: true, url: file, urlList: multiValue ? multiValue : urlListt });
  };

  const downloadFile = (file) => () => window.open(file, "_blank");

  const inputRef = useRef(null);

  const onFileSelect = useCallback(async (e) => {
    const acceptableFiles = ["png", "jpg", "jpeg", "pdf", "docx", "doc"];
    let fileExtension = general.getFileExtension(e.target.files[0]?.name);
    if (!acceptableFiles.includes(fileExtension.toLowerCase())) {
      general.notificationWarning(i18next.t("validation.file_upload.file_format_not_supported"));
      return;
    }
    if (e.target?.files?.length > 0) {
      if (maxFileCount > 1) {
        for (let i = 0; i < e.target.files.length; i++) if (i < maxFileCount) await uploadFile(e.target.files[i]);
      } else await uploadFile(e.target.files[0]);
    }
    setKeyForInput(general.generateRandomString(10));
  }, []);

  const onClickSelectFile = useCallback((e) => {
    e.preventDefault();
    if (inputRef.current) inputRef.current.click();
  }, []);

  const uploadFile = useCallback(async (file) => {
    if (cropFile) {
      var res = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = (e) => {
          dispatch(
            ModalActions.openModal({
              type: ModalTypes.CROP_FILE_MODAL,
              modalProps: {
                image: e.currentTarget.result,
                onImageCropped: (image) => {
                  fetch(image)
                    .then((res) => res.arrayBuffer())
                    .catch(resolve)
                    .then((buf) => resolve(new File([buf], file.name, { type: file.type })))
                    .catch(resolve);
                },
                onCancel: () => resolve(null),
              },
            })
          );
        };
        reader.readAsDataURL(file);
      });

      if (!res) return;
      file = res;
    }

    //upload process
    const formData = new FormData();
    formData.append("file", file);
    formData.append("bucket", bucket);

    if (file.size > 10 * 1024 * 1024) {
      general.notificationError("validation.file_upload.file_size_too_large");
      return;
    } else {
      setUploadPercent(0);
      setLoading(true);
      if (onChangeLoading instanceof Function) onChangeLoading(true);
      var uploadResult = await apiService.makeApiCall({
        controller: "file",
        action: "upload",
        method: "post",
        axiosOptions: {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + global?.token,
            Accept: "application/json",
          },
          onUploadProgress: (progressEvent) => {
            var percentCompleted = Math.round(progressEvent.progress * 100);
            setUploadPercent(percentCompleted);
          },
          data: formData,
        },
      });
      setLoading(false);
      if (onChangeLoading instanceof Function) onChangeLoading(false);
      if (uploadResult.error) {
        general.notificationError(uploadResult.errorMessage);
        return;
      }

      if (onChange instanceof Function) onChange(JSON.stringify([...filesRef.current, uploadResult.data]));
    }
  }, []);

  if (readOnly && hideWhenNotFileUploadedOnReadOnlyMode && files.length == 0) return null;

  return (
    <div>
      <Modal visible={imageViewerState.modalOpen} footer={null} width={1000} title={i18next.t("general.file_viewer")} onCancel={() => setImageViewerState((curr) => ({ ...curr, modalOpen: false }))}>
        <div>
          <ImageSlider images={imageViewerState.urlList} />
        </div>
      </Modal>

      <Form.Item
        validateStatus={general.isNullOrEmpty(errorMessage) ? "" : "error"}
        help={errorMessage}
        {...antdFormItemProps}
      >

        <div className="border-style file-container">
          <p style={{ marginBottom: 0 }}>{i18next.t(label)}</p>
          {readOnly && files?.length === 0 && <p style={{ textAlign: "center", marginTop: 40, marginBottom: 40 }}>{i18next.t("general.no_files_found")}</p>}

          {/* FILE LIST */}
          <div className="file-list">
            {files?.map((file, index) => {
              const extension = general.getFileExtension(file);
              const isImage = general.isImageExtension(extension);

              return (
                <div key={index} className="file-item">

                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      if (isImage) showImage(index);
                      else window.location = file;
                    }}
                  >
                    {isImage && <img className="type-image" src={file} height={125} width={125} />}
                    {!isImage && (
                      <div className="type-file">
                        <FileDoneOutlined />
                        <p style={{ marginBottom: 0 }}>
                          {extension} {i18next.t("general.file")}
                        </p>
                        {file != null && <p style={{ marginBottom: 0, textAlign: "center" }}>{file.length > 30 ? file.substring(0, 30) : file}</p>}
                      </div>
                    )}
                  </a>
                  <div className="file-buttons">
                    {isImage && <FormAction onClick={showImage(file)} iconNode={<EyeOutlined />} />}
                    <FormAction
                      onClick={downloadFile(file)}
                      buttonType="success"
                      iconNode={<DownloadOutlined />}
                    />
                    {!readOnly && (
                      <GridPopconfirmAction
                        actionProps={{
                          buttonType: "danger",
                          iconNode: <DeleteOutlined />,
                        }}
                        onClickYes={deleteFile(file)}
                      />
                    )}
                  </div>
                </div>
              );
            })}

            {/* ADD FILE BUTTON */}
            {files?.length < maxFileCount && !readOnly && (
              <div className="file-item-add">
                <input
                  accept={accept}
                  key={keyForInput}
                  max={maxFileCount}
                  multiple={maxFileCount > 1}
                  onSelect={onFileSelect}
                  value={null}
                  style={{ display: "none" }}
                  ref={inputRef}
                  type="file"
                  onChange={onFileSelect}
                />

                <a href="#" onClick={onClickSelectFile}>

                  {loading ? <LoadingOutlined /> : <PlusOutlined />}
                  <p style={{ marginBottom: 0, marginTop: 5 }}>
                    {loading && (uploadPercent == 100 ? i18next.t("general.processing") : uploadPercent + "%")}
                  </p>
                </a>
              </div>
            )}
          </div>
        </div>
      </Form.Item>
    </div>
  );
};

export default FormMultiFileUpload;

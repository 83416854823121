import axios from "axios";
import CommandBus from "../../infrastructure/command-bus/command-bus";
import i18next from "i18next";
import general from "../../utils/general";
import AppConfig from "../../utils/config/app-config";
import ApiStatusEnum from "../../utils/enums/api-status-enum";

export type ApiCallPayload = {
    uri?: string;
    controller?: string;
    action?: string | null;
    itemId?: string;
    status?: string;
    data?: any;
    query?: any;
    method?: string;
    axiosOptions?: any;
    enabled?: boolean;
    refetchOnWindowFocus?: boolean;
    showAlertOnError?: boolean;
    headers?: object;
    token?: string;
    onSuccess?: (result: Result) => void;
    onError?: (result: Result) => void;
    callback?: (result: Result) => void;
    showAlertOnSuccess?: boolean;
    showAlertOnErrors?: boolean;
}

export type Result = {
    success?: boolean;
    error?: boolean;
    data?: any;
    errorMessage?: string;
    pagination?: any;
    status?: number;
}


const makeApiCall = async (payload: ApiCallPayload): Promise<Result> => {
    try {


        let url = "";

        if (!general.isNullOrEmpty(payload.uri))
            url = payload.uri ?? '';
        else {
            url = `${AppConfig.API_URL}/${payload.controller}`;
            if (!general.isNullOrEmpty(payload.action))
                url += `/${payload.action}`;
            if (!general.isNullOrEmpty(payload.itemId))
                url += `/${payload.itemId}`;
            if (!general.isNullOrEmpty(payload.status))
                url += `/${payload.status}`;
            if (payload.query instanceof Object) {
                const keys = Object.keys(payload.query);
                if (keys.length > 0) url += "?";

                for (let i = 0; i < keys.length; ++i) {
                    url += keys[i] + "=" + ((payload.query[keys[i]] instanceof Object || payload.query[keys[i]] instanceof Array) ? encodeURI(JSON.stringify(payload.query[keys[i]])) : encodeURI(payload.query[keys[i]]));
                    if (i < keys.length - 1)
                        url += "&";
                }
            }
        }

        if (payload.method?.toLowerCase() === "get")
            url += (url.includes("?") ? "&" : "?") + "languageCode=" + i18next.language;
        else
            payload.data = {
                ...payload.data,
                languageCode: i18next.language
            }


        let options = {
            url,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + payload.token,
                ...payload?.headers
            },
            method: payload.method,
            data: payload.data,
            ...payload?.axiosOptions
        };

        const res = await axios(options);

        if (res.data.status === ApiStatusEnum.Success) {
            return {
                success: true,
                data: res.data.data,
                pagination: res.data.pagination
            }
        }

        if (res.data.status === ApiStatusEnum.Error) {
            if (payload.showAlertOnError !== false && res.status != 401)
                CommandBus.sc.alertError(i18next.t(res.data.exceptionMessage));


            return {
                error: true,
                data: res.data.data,
                errorMessage: res.data.exceptionMessage,
                status: res.data.status
            }
        }

        if (payload.showAlertOnError !== false)
            CommandBus.sc.alertError(i18next.t("general.unhandled_error") + " status: " + res.data.status);


        return {
            error: true,
            data: res.data.data,
            errorMessage: i18next.t("general.unhandled_error") + " status: " + res.data.status,
            status: res.data.status
        }

    } catch (error: any) {
        if (error?.response?.status === 401) {
            localStorage.removeItem("token")
        }
        console.log("error", error);
        if (!general.isNullOrEmpty(error?.response?.data?.status)) { // error, but not network error. Have api error

            if (payload.showAlertOnError !== false)
                CommandBus.sc.alertError(i18next.t(error.response.data.exceptionMessage));

            return {
                error: true,
                data: error.response.data.data,
                errorMessage: error.response.data.exceptionMessage,
                status: error.response.data.status
            }
        }

        if (payload.showAlertOnError !== false)
            CommandBus.sc.alertError(i18next.t(error?.message ?? error.response.data.exceptionMessage ?? "general.unhandled_error"));

        return {
            error: true,
            data: null,
            errorMessage: i18next.t(error?.message ?? error.response.data.exceptionMessage ?? "general.unhandled_error"),
            status: error?.response?.status
        };
    }
};


export default {
    makeApiCall
}
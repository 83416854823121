import { ApiCallPayload } from "./api-service";


function List(payload: ApiCallPayload) {
    return { type: "LIST", payload };
}

function Dropdown(payload: ApiCallPayload) {
    return { type: "DROPDOWN", payload };
}


function Detail(payload: ApiCallPayload) {
    return { type: "DETAIL", payload };

}

function Save(payload: ApiCallPayload) {
    if (payload.showAlertOnSuccess != false) {
        payload.showAlertOnSuccess = true;
    }
    return { type: "SAVE", payload };

}

function Delete(payload: ApiCallPayload) {
    if (payload.showAlertOnSuccess != false) {
        payload.showAlertOnSuccess = true;
    }
    return { type: "DELETE", payload };

}

function Update(payload: ApiCallPayload) {
    if (payload.showAlertOnSuccess != false) {
        payload.showAlertOnSuccess = true;
    }
    return { type: "UPDATE", payload };

}

function UpdateStatus(payload: ApiCallPayload) {
    return { type: "UPDATE_STATUS", payload };

}

function ApiCall(payload: ApiCallPayload) {
    return { type: "BASE_API_CALL", payload };

}

function NativeGet(payload: ApiCallPayload) {
    return { type: "NATIVE_GET", payload };
}

function NativePost(payload: ApiCallPayload) {
    return { type: "NATIVE_POST", payload };
}



export default { List, Detail, Save, Delete, Update, UpdateStatus, ApiCall, Dropdown, NativeGet, NativePost }
import "../../../components/high-level/rodal/rodal.css";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Actions as ModalActions } from "../../../redux/modal/reducers";
import ModalTypes from "../../../utils/modal-types";
import ChangePasswordModal from "../../../modals/change-password/change-password-form";

const ModalRenderContainer = ({ }) => {
  const dispatch = useDispatch();
  const modals = useSelector(state => state.modal.modals);
  const history = useHistory()
  useEffect(() => {
    return history.listen((location) => {
      dispatch(ModalActions.setModalState({ modals: [] }))
    })
  }, [history])

  const closeModal = (nodeKey) => () => {
    dispatch(ModalActions.closeModal({ nodeKey }))
  }

  return (
    <div>
      {modals?.map(item => <div key={item?.nodeKey}>
        {item.type === ModalTypes.CHANGE_PASSWORD_FORM &&
          <ChangePasswordModal key={item?.nodeKey + "rM"} nodeKey={item?.nodeKey} {...item.modalProps} closeModal={closeModal(item?.nodeKey)} />
        }
      </div>)}
    </div>)
}

export default ModalRenderContainer;

import store from 'store'
import { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { Actions as RootActions } from '../redux/root/reducers'
import { Actions as AuthActions } from '../redux/auth/reducers'

import general from '../utils/general'
import apiCalls from '../services/api/api-calls'

const loadi18Next = ({ onSuccess }) => {

  general.changeLanguage(localStorage.getItem('language'))
    .then(() => onSuccess())
    .catch(err => {
      setTimeout(() => {
        loadi18Next({ onSuccess })
      }, 2000)
    })
}

const loadUserInfo = ({ dispatch, onSuccess }) => {
  const token = store.get('token') || ''
  if (!general.isNullOrEmpty(token)) {
    //user not logged in
    dispatch(
      AuthActions.setAuthState({
        // set token
        token: token,
        isUserAuthorized: true,
      }),
    )
    global.token = token
    var countError = 0
    dispatch(
      apiCalls.NativeGet({
        controller: 'auth',
        action: 'UserInfo',
        showAlertOnError: true,
        onSuccess: ({ data }) => {
          if (data == null) {
            localStorage.clear()
          }
          dispatch(
            AuthActions.setAuthState({
              userInfo: data,
            }),
          )
          global.userInfo = data
          global.token = token
          global.roles = new Set(data?.role?.actions)
          onSuccess()
        },
        onError: () => { },
      }),
    )
  } else {
    onSuccess()
  }
}

const LoadApp = ({ }) => {
  const dispatch = useDispatch()
  useEffect(() => {

    dispatch(
      RootActions.setRootState({
        i18nextLoading: false,
      }),
    )
    loadi18Next({
      onSuccess: () => {
        loadUserInfo({
          dispatch,
          onSuccess: () => {
            dispatch(
              RootActions.setRootState({
                appIsLoading: false,
              }),
            )
          },
        })
      },
    })
  }, [])
  return null
}

export default LoadApp

import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

function ImageSlider({ images }) {
  const CustomPrevArrow = (props) => (
    <div className="custom-arrow custom-prev-arrow" onClick={props.onClick}>
      {"<"}
    </div>
  );

  const CustomNextArrow = (props) => (
    <div className="custom-arrow custom-next-arrow" onClick={props.onClick}>
      {">"}
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: images?.length > 1 ?  <CustomPrevArrow /> : null,
    nextArrow: images?.length > 1 ? <CustomNextArrow /> : null,
    adaptiveHeight: true,
  };

  return (
    <div className="image-slider-container">
      <Slider {...settings}>
        {images.map((imageUrl, index) => (
          <div className="slider-image-container" key={index}>
            <img src={imageUrl} alt={`Image ${index + 1}`} className="slider-image" />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default ImageSlider;

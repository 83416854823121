import { ConnectedRouter } from "connected-react-router";
import React, { Suspense, lazy } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";

import Layout from "./layouts";

const routes = [
  // Public Pages
  {
    path: "/after-scan/:id",
    Component: lazy(() => import("./pages/public/after-scan")),
    exact: true,
  },
  {
    path: "/send-notification/:id",
    Component: lazy(() => import("./pages/public/send-notification")),
    exact: true,
  },
  {
    path: "/go-app",
    Component: lazy(() => import("./pages/public/go-app")),
  },
  {
    path: "/goApp",
    Component: lazy(() => import("./pages/public/go-app")),
  },
  {
    path: "/goApp/open",
    Component: lazy(() => import("./pages/public/go-app")),
  },
  {
    path: "/change-language",
    Component: lazy(() => import("./pages/public/change-language")),
  },

  // Guest Pages
  {
    path: "/guest/index",
    Component: lazy(() => import("./pages/guest/index")),
    exact: true,
  },

  // Auth Pages
  {
    path: "/auth/login",
    Component: lazy(() => import("./pages/auth/login")),
    exact: true,
  },
  {
    path: "/auth/forgot-password",
    Component: lazy(() => import("./pages/auth/forgot-password")),
    exact: true,
  },
];

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings?.routerAnimation,
});

const Router = ({ history, routerAnimation }) => {
  return (
    <>
      <ConnectedRouter history={history}>
        <Layout history={history}>
          <Route
            render={(state) => {
              const { location } = state;
              return (
                <SwitchTransition>
                  <CSSTransition key={location?.pathname} appear classNames={routerAnimation} timeout={routerAnimation === "none" ? 0 : 300}>
                    <Switch location={location}>
                      <Route exact path="/" render={() => <Redirect to="/home/index" />} />
                      {routes.map(({ path, Component, exact }) => (
                        <Route
                          path={path}
                          key={path}
                          exact={exact}
                          render={() => {
                            return (
                              <div className={routerAnimation}>
                                <Suspense fallback={null}>
                                  <Component />
                                </Suspense>
                              </div>
                            );
                          }}
                        />
                      ))}
                      <Redirect to="/auth/404" />
                    </Switch>
                  </CSSTransition>
                </SwitchTransition>
              );
            }}
          />
        </Layout>
      </ConnectedRouter>
    </>
  );
};

export default connect(mapStateToProps)(Router);

import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";
import i18next from "i18next";
import React, { useMemo, useState } from "react";
import { PiPassword } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import store from "store";
import AppMenu from "../../components/cleanui/layout/Menu";
import ModalRenderContainer from "../../components/high-level/modal/modal-render-container";
import { Actions as AuthActions } from "../../redux/auth/reducers";
import { Actions as ModalActions } from "../../redux/modal/reducers";
import getMenuData from "../../services/menu/index";
import ModalTypes from "../../utils/modal-types";

const { Header, Content, Footer, Sider } = Layout;

const MainLayout = (params) => {
  const { children, history } = params;
  const pathName = history.location?.pathname;
  const userInfo = useSelector((state) => state.auth.userInfo);
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(true);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const menuItems = useMemo((e) => {
    const menuData = getMenuData().map((item) => {
      return {
        key: item.key,
        icon: item.icon,
        label: i18next.t(item.label),
        url: item.url,
        onClick: () => (item.url ? (window.location.href = "/#" + item.url) : null),
        children: item.children?.map((child) => {
          return {
            key: child.key,
            label: i18next.t(child.label),
            icon: child.icon,
            url: child.url,
            onClick: () => (window.location.href = "/#" + child.url),
          };
        }),
      };
    });

    return menuData;
  }, []);

  const logOut = () => {
    store.remove("token");
    localStorage.clear();
    window.location.reload();
    dispatch(
      AuthActions.setAuthState({
        isUserAuthorized: false,
        token: "",
      })
    );
  };

  const openChangePasswordModal = () => {
    dispatch(
      ModalActions.openModal({
        type: ModalTypes.CHANGE_PASSWORD_FORM,
      })
    );
  };

  const breadCrumbValues = useMemo(() => {
    const parent = menuItems.find((item) => item.children?.find((child) => child.url == pathName));
    const child = parent?.children?.find((child) => child.url == pathName);

    // var list =
    //   [
    //     // {
    //     //   path: '/dashboard/alpha',
    //     //   title: <HomeOutlined />,
    //     // },
    //   ];

    // if (parent) {
    //   list.push({
    //     breadcrumbName: parent.title,
    //   });
    // }

    // if (child) {
    //   list.push({
    //     breadcrumbName: child.title,
    //   });
    // }

    // return list;
  }, [menuItems, pathName]);

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <AppMenu />
      <Layout>
        <Header
          style={{
            display: "flex",
            alignItems: "center",
            background: "#fff",
          }}
        >
          <Menu
            theme="light"
            mode="horizontal"
            items={[
              {
                key: "1",
                icon: <UserOutlined />,
                label: userInfo?.name,
                className: "header-profile",
                children: [
                  {
                    key: "1",
                    label: i18next.t("general.change_password"),
                    icon: <PiPassword />,
                    onClick: openChangePasswordModal,
                  },
                  {
                    key: "2",
                    label: i18next.t("general.logout"),
                    icon: <LogoutOutlined />,
                    onClick: logOut,
                  },
                ],
              },
            ]}
            className="menu-topbar"
          />
        </Header>
        <Content
          style={{
            margin: "0 16px",
            backgroundColor: "white",
          }}
        >
          {/* <Breadcrumb
            style={{
              margin: '16px 0',
            }}
            routes={breadCrumbValues}
          /> */}
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            {children}
          </div>

          <ModalRenderContainer />
        </Content>

        <Footer
          style={{
            textAlign: "center",
          }}
        >
          QR4CAR ©{new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
};
export default MainLayout;

import React, { Fragment } from 'react'
import { Redirect, withRouter, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import PublicLayout from './Public'
import AuthLayout from './Auth'
import MainLayout from './Main'


const Layouts = {
  public: PublicLayout,
  auth: AuthLayout,
  main: MainLayout,
}

const Layout = ({ children, location: { pathname, search, previousPath }, history }) => {
  const appIsLoading = useSelector(state => state.root.appIsLoading);
  const i18nextLoading = useSelector(state => state.root.i18nextLoading);
  const isUserAuthorized = useSelector(state => state.auth.isUserAuthorized);

  // Layout Rendering
  const getLayout = () => {
    if (/^\/auth(?=\/|$)/i.test(pathname)) {
      return 'auth'
    }
    if (/^\/public(?=\/|$)/i.test(pathname)) {
      return 'public'
    }
    else if (/^\/after-scan(?=\/|$)/i.test(pathname)) {
      return 'public'
    }
    else if (/^\/go-app/.test(pathname) || /^\/goApp/.test(pathname)) {
      return 'public'
    }
    else if (/^\/change-language/.test(pathname)) {
      return 'public'
    }
    else if (/^\/send-notification/.test(pathname)) {
      return 'public'
    }
    else if (/^\/guest(?=\/|$)/i.test(pathname)) {
      return 'public'
    }
    return 'main'
  }

  const Container = Layouts[getLayout()]
  const isAuthLayout = getLayout() === 'auth'
  const isPublicLayout = getLayout() === 'public'

  const BootstrappedLayout = () => {
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (appIsLoading || i18nextLoading) {
      return <div className="initial__loading"></div>;
    }
    // redirect to login page if current is not login page and user not authorized
    if (!isAuthLayout && !isUserAuthorized && !isPublicLayout) {
      return <Redirect to="/guest/index" />
    }

    //redirect to dashboard page if user logged in
    if (isUserAuthorized && isAuthLayout) {
      return <Link to="/home/index" />
    }

    // in other case render previously set layout
    return <Container history={history} >{children}</Container>
  }

  return (
    <Fragment>
      <Helmet titleTemplate="QR4Car | %s" title="QR4Car" />
      {BootstrappedLayout()}
    </Fragment>
  )
}

export default withRouter(Layout)

export default function getMenuData() {
  const isIndependentAdmin = !global.userInfo?.subContractorId;
  console.log(isIndependentAdmin);

  // Home Menu
  var homeMenu = {
    title: "left_menu.home",
    key: "home",
    icon: "fe fe-home",
    url: "/dashboard/alpha",
  };

  // Management Menu
  var managementMenuSubs = [];
  var managementMenu = {
    title: "left_menu.management",
    key: "management",
    icon: "fe fe-settings",
    children: managementMenuSubs,
  };

  managementMenuSubs.push({
    title: "left_menu.user_list",
    key: "users",
    url: "/user",
  });

  managementMenuSubs.push({
    title: "left_menu.workplace_list",
    key: "workplaces",
    url: "/workplace",
  });

  managementMenuSubs.push({
    title: "left_menu.user_document_list",
    key: "user-documents",
    url: "/user-documents",
  });

  if (isIndependentAdmin) {
    managementMenuSubs.push({
      title: "left_menu.general_document_list",
      key: "general-documents",
      url: "/general-documents",
    });
  }

  managementMenuSubs.push({
    title: "left_menu.ticket_list",
    key: "tickets",
    url: "/tickets",
  });

  if (isIndependentAdmin) {
    managementMenuSubs.push({
      title: "left_menu.subcontractor_list",
      key: "subcontractors",
      url: "/subcontractors",
    });

    managementMenuSubs.push({
      title: "left_menu.screen_record_log_list",
      key: "screen-record-logs",
      url: "/screen-record-logs",
    });

    managementMenuSubs.push({
      title: "left_menu.translate_list",
      key: "translate",
      url: "/translate",
    });
  }

  var menu = [];

  menu.push(homeMenu);

  if (managementMenuSubs.length > 0) menu.push(managementMenu);
  return menu;
}

import React from "react";
import useCommandBusListener from "../../../infrastructure/command-bus/hooks/use-command-bus-listener";
import { CommandTypeEnum } from "../../../infrastructure/command-bus/command-type-enum";
import { notification } from "antd";
import i18next from "i18next";


const ErrorHandler = () => {
    const [api, contextHolder] = notification.useNotification();

    const handleError = (error: { type: 'error' | 'warning' | 'info' | 'success', message: string }) => {
        const payload  = {
            message: i18next.t("general.alert_" + error.type).toString(),
            description: error.message,
            showProgress: true,
            pauseOnHover: true,
        };

        switch (error.type) {
            case 'error':
                api.error(payload);
                break;
            case 'warning':
                api.warning(payload);
                break;
            case 'info':
                api.info(payload);
                break;
            case 'success':
                api.success(payload);
                break;
            default:
                break;
        }
    }

    useCommandBusListener(CommandTypeEnum.Alert, handleError);

    return (
        <div>
            {contextHolder}
        </div>
    );

}

export default ErrorHandler;
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import i18next from "i18next";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { FormAction, FormContainer } from "../../components/high-level/form/index.js";
import ModalFullWidthContainer from "../../components/high-level/modal/modal-full-width-container.js";
import Validator from "../../components/high-level/validator/index.js";
import { Actions as ApiCallActions } from "../../redux/apiCall/reducers.ts";
import general from "../../utils/general.js";

import FormInputPassword from "../../components/high-level/form/form-input-password.js";

const ChangePasswordModal = ({ nodeKey, id, closeModal }) => {
  const dispatch = useDispatch();

  //#region State Operations

  const [, reRender] = useState(null);
  const [validator] = useState(new Validator());
  const validatorScopeKey = validator.generateNewScopeKey();

  const [loading, setLoading] = useState(false);
  const [formProps, setFormProps] = useState({});
  const updateFormProps = (values) => setFormProps((curr) => ({ ...curr, ...values }));

  const onChange = (name) => (value) => updateFormProps({ [name]: value });

  //#endregion

  //#region Api Calls

  const save = useCallback(() => {
    const isValid = validator.allValid();
    reRender(1);
    if (isValid) {
      setLoading(true);
      const formData = {
        email: global.userInfo?.email,
        oldPassword: formProps?.oldPassword,
        password: formProps?.password,
      };
      // create
      dispatch(
        ApiCallActions.NativePost({
          controller: "auth",
          action: "changePassword",
          data: formData,
          showLoading: true,
          onSuccess: async ({ data }) => {
            closeModal?.();
            general.messageSuccess("general.change_password_success");
          },
          onError: (message) => general.notificationError(message.errorMessageTechnical),
          callback: () => setLoading(false),
        })
      );
    }
  }, [formProps]);
  //#endregion

  return (
    <ModalFullWidthContainer nodeKey={nodeKey}>
      <FormContainer
        headerActions={[
          {
            show: true,
            node: <FormAction text={i18next.t("form.button.close")} iconNode={<CloseOutlined />} buttonType="light" onClick={closeModal} />,
          },
        ]}
        footerActions={[
          {
            show: true,
            node: <FormAction enableFunctionEqualityCheckForMemo loading={loading} text={i18next.t("form.button.save")} iconNode={<SaveOutlined />} buttonType="success" onClick={save} />,
          },
        ]}
        headerTitle={i18next.t("change_password.form_title")}
      >
        <p style={{ fontSize: 16, marginBottom: 30 }}>{i18next.t("change_password.subtitle")}</p>
        <div className="d-flex flex-column">
          <div className="col-12 col-sm-6 col-md-4 col-lg-4">
            <FormInputPassword
              key={"oldPassword"}
              addRequiredSignToLabel
              label={i18next.t("change_password.form.old_password")}
              value={formProps?.oldPassword}
              onChange={onChange("oldPassword")}
              generatePasswordActive={false}
              errorMessage={validator.register(
                "oldPassword",
                formProps?.oldPassword,
                [{ rule: "required" }, { rule: "minStringLength", value: 6 }, { rule: "maxStringLength", value: 25 }],
                validatorScopeKey
              )}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-4" style={{ marginTop: 10 }}>
            <FormInputPassword
              key={"password"}
              addRequiredSignToLabel
              label={i18next.t("change_password.form.new_password")}
              value={formProps?.password}
              onChange={onChange("password")}
              errorMessage={validator.register(
                "password",
                formProps?.password,
                [{ rule: "required" }, { rule: "minStringLength", value: 6 }, { rule: "maxStringLength", value: 25 }],
                validatorScopeKey
              )}
            />
          </div>

          <div className="col-12 col-sm-6 col-md-4 col-lg-4" style={{ marginTop: 10 }}>
            <FormInputPassword
              key={"checkPassword"}
              addRequiredSignToLabel
              label={i18next.t("change_password.form.new_password_again")}
              value={formProps.checkPassword}
              onChange={onChange("checkPassword")}
              generatePasswordActive={false}
              errorMessage={validator.register(
                "checkPassword",
                formProps?.checkPassword,
                [{ rule: "required" }, { rule: "minStringLength", value: 6 }, { rule: "maxStringLength", value: 25 }, { rule: "areEqual", value: formProps.password }],
                validatorScopeKey
              )}
            />
          </div>
        </div>
      </FormContainer>
    </ModalFullWidthContainer>
  );
};

export default ChangePasswordModal;

import React, { useState } from "react";

import "./index.css";

const FloatLabel = props => {
  const [focus, setFocus] = useState(false);
  const { children, label, value,disabled,date } = props;
  let labelClass = null
  if(date){
    labelClass = disabled ? "label-date label-float-disabled" : focus || ((value || value==0) && value.length !== 0) ? "label label-float" : "label label-float"
  }else{
    labelClass = disabled ? "label label-float-disabled" : focus || ((value || value==0) && value.length !== 0) ? "label label-float" : "label"
  }

  

  return (
    <div
      className="float-label"
      style={{display:'flex',flexDirection:'row'}}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {children}
      <label className={labelClass}>{label}</label>
    </div>
  );
};

export default FloatLabel;

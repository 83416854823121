import { withRouter } from 'react-router-dom'

const PublicLayout = ({ children }) => {
  return <>


  
    {children}
  </>
}

export default withRouter(PublicLayout)

import { Form, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import FloatLabel from "../../low-level/float-label";
import React, { useEffect, useState } from "react";
import general from "../../../utils/general";
import useRefState from "../../../utils/use-ref-state";
import i18next from "i18next";

const FormInput = ({
  label,
  value,
  errorMessage,
  onChange,
  onFocus,
  onBlur,
  onClick,
  prefix,
  suffix,
  antdFormItemProps,
  antdInputProps,
  rightAction,
  multiLine = false,
  rows = 10,
  cols = 10,
  addRequiredSign = false,
  disabled,
  readOnly,
  onKeyPress,
  className = "",
  useNumberFormat = false,
  usePriceFormat = false,
  forceLowerCase = false,
  forceUpperCase = false,
  helperText,
  maxLength,
  keyboardType = "text",
  allowClear = true,
  inputRef = null,
  placeholderValue = "",
  useFormattedValue=true
}) => {
  const [formattedValue, formattedValueRef, setFormattedValue] = useRefState(value);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    onFocusEvent();
  }, []);

  useEffect(() => {
    if ((!isLoaded && value) || formattedValueRef.current != value) {
      if (usePriceFormat) {
        var val = value?.toString().replaceAll(".", ",");
        setFormattedValue(general.formatPrice(val, true));
      } else if (useNumberFormat) {
        setFormattedValue(general.formatNumber(value));
      } else {
        setFormattedValue(value);
      }
      setIsLoaded(true);
    }
  }, [value, isLoaded]);

  const onChangeEvent = (e) => {
    var val = e.target.value;
    if (useNumberFormat) {
      setFormattedValue(general.formatNumber(val));
      val = val.replaceAll(".", "");
    } else if (usePriceFormat) {
      setFormattedValue(general.formatPrice(val, true));
      val = val.toString().replaceAll("₺", "").replaceAll(".", "").replaceAll(",", ".");
    } else {
      setFormattedValue(val);
    }

    if (forceLowerCase) val = general.forceLowerCase(val);

    if (forceUpperCase) val = general.forceUpperCase(val);

    if (onChange instanceof Function) onChange(val);
  };
  const onClickEvent = (e) => {
    if (onClick instanceof Function) onClick(e.target.value);
  };
  const onFocusEvent = () => {
    if (onFocus instanceof Function) onFocus();

    if (usePriceFormat) {
      setFormattedValue(general.formatPrice(value?.toString().replaceAll(".", ","), true));
    }
  };
  const onBlurEvent = () => {
    if (onBlur instanceof Function) onBlur();

    if (usePriceFormat) {
      setFormattedValue(general.formatPrice(value?.toString().replaceAll(".", ",")));
    }
  };

  return (
    <Form.Item
      validateStatus={general.isNullOrEmpty(errorMessage) ? "" : "error"}
      help={errorMessage ? errorMessage : helperText ?? helperText}
      {...antdFormItemProps}
    >
      {!multiLine && (
        <FloatLabel label={i18next.t(label) + (addRequiredSign ? "*" : "")} name={label} value={value} disabled={disabled}>
          <Input
            ref={inputRef}
            maxLength={maxLength}
            autoComplete="new-password"
            suffix={prefix}
            allowClear={allowClear}
            className={"border-style " + className}
            onKeyPress={onKeyPress}
            value={useFormattedValue===true?formattedValue:!general.isNullOrEmpty(value)?value:""}
            disabled={disabled}
            readOnly={readOnly}
            size="large"
            onChange={onChangeEvent}
            onClick={onClickEvent}
            onFocus={onFocusEvent}
            onBlur={onBlurEvent}
            type={keyboardType}
            placeholder={placeholderValue ? i18next.t(placeholderValue) + (addRequiredSign ? "*" : "") : ""}
            {...antdInputProps}
          />
        </FloatLabel>
      )}

      {multiLine && (
        <FloatLabel label={i18next.t(label)} name={label} value={value}>
          <TextArea
            prefix={prefix}
            suffix={suffix}
            allowClear={allowClear}
            className={"border-style " + className}
            onKeyPress={onKeyPress}
            value={value}
            rows={rows}
            cols={cols}
            size="large"
            disabled={disabled}
            readOnly={readOnly}
            onChange={onChangeEvent}
            onClick={onClickEvent}
            onFocus={onFocusEvent}
            onBlur={onBlurEvent}
            {...antdInputProps}
          />
        </FloatLabel>
      )}
      {rightAction}
    </Form.Item>
  );
};

const areEqual = (prev, next) => {
  return prev.label === next.label &&prev.useFormattedValue===next.useFormattedValue&& prev.placeholderValue === next.placeholderValue && prev.errorMessage === next.errorMessage && prev.disabled === next.disabled && prev.value == next.value;
};
export default React.memo(FormInput, areEqual);
